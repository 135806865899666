<template>
  <div>
    <Steps class="p-t-10" :current="current">
      <Step v-for="(step,i) in steps" :key="i" :title="step.title" :content="step.desc"></Step>
    </Steps>

    <component ref="stepComponent" :is="component" :params="params" :isPlatform="true" @next-step="handleNextStep" @prev-step="handlePrevStep" @on-submit="handleSubmit"/>

  </div>
</template>

<script>
import basic from '@/components/contract/contractDetail/edit/Basic'
import price from '@/components/contract/contractDetail/edit/Price'
import commission from '@/components/contract/contractDetail/edit/Commission'
import confirmEdit from '@/components/contract/contractDetail/edit/ConfirmEdit'
import upload from '@/components/contract/contractDetail/edit/Upload'

import { checkContractDetail, updateDraft } from '@/api/scp/contract'
import { ParseDate } from '@/utils/dateFormat'

export default {
  props: {
    baseId: {
      type: Number,
      required: true
    }
  },
  components: {
    basic, price, commission, confirmEdit, upload
  },
  data () {
    return {
      id: this.baseId,
      current: 0,
      contract: {
        contractName: '',
        advertiserId: 0,
        mark: '',
        firstParty: {},
        payer: {},
        orders: [],
        priceOrder: [],
        payments: [],
        isStandard: true,
        kpiType: 0,
        userId: 0,
        companyId: 0,
        advertiserResultBean: {},
        id: 0,
        versionId: 0,
        schedulePrice: []
      },
      component: '',
      params: {}
    }
  },
  methods: {
    initPageData () {
      checkContractDetail({ contractId: this.id }).then(res => {
        this.contract = res
        this.contract.orders = []
        this.contract.payments = res.paymentBeanList.map(item => {
          item.money = item.amount
          return item
        })
        this.contract.priceOrder = []
        this.contract.companyId = this.$store.getters.token.userInfo.companyId
        this.contract.publisherId = this.$store.getters.token.userInfo.publisherId
        this.contract.id = this.id
        this.params = this.contract
        this.component = 'basic'
      })
    },
    handleNextStep (data) {
      const step = this.steps[this.current]
      let currentContract = {}
      switch (step.name) {
        case 'basic':
          currentContract = {
            contractId: data.contractId,
            mark: data.mark,
            contractCategory: data.contractCategory,
            name: data.name,
            customerId: data.customerId
          }
          data = Object.assign({}, data, { contractName: data.name })
          break
        case 'price':
          currentContract = {
            contractId: data.contractId,
            orderIds: JSON.stringify(data.orders.map(x => x.id)),
            payments: JSON.stringify(
              data.payments.filter(x => x.money > 0).map(payment => {
                return {
                  date: ParseDate(payment.date),
                  amount: payment.money,
                  mark: payment.mark
                }
              }))
          }
          break
        case 'commission':
          currentContract = data
          break
        default:
          break
      }
      if (currentContract.contractId > 0) {
        updateDraft(currentContract).then(res => {
          if (res && !res.errcode) {
            this.current++
            this.contract = Object.assign({}, this.contract, data)
          }
        }).catch(() => {
          this.$refs.stepComponent.cannelLoading()
        })
      } else {
        this.current++
      }
    },
    handlePrevStep (data) {
      this.current--
    },
    handleSubmit () {
      this.$emit('on-submit')
    }
  },
  watch: {
    current: function () {
      this.component = this.steps[this.current].name
      this.params = this.contract
    },
    baseId (val) {
      this.id = val
      this.initPageData()
    }
  },
  created () {
    this.initPageData()
  },
  computed: {
    steps: function () {
      const allowCommission = this.$store.getters.token.publisherSetting.companyPerformance ? 0 : 1
      const steps = [
        { title: '基础信息', desc: '配置合同基础信息', name: 'basic' },
        { title: '付款项', desc: '配置订单付款项、合同付款时间', name: 'price' },
        { title: '绩效预览', desc: '根据合同的最终执行价，预览激励绩效', name: 'commission', allows: [0] },
        { title: '提交附件', desc: '如果需要，提交一些附件', name: 'upload' },
        { title: '确认合同信息', desc: '确认合同全部信息，并提交合同', name: 'confirmEdit' }
      ]

      return steps.filter(x => !x.allows || x.allows.includes(allowCommission))
    }

  }

}
</script>
