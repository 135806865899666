<template>
    <div style="margin:10px">
      <row class="p-b-10">
       <span class="title"> 所属客户</span>{{model.advertiserResultBean.name}}
      </row>
      <row class="p-b-10">
        <span class="title">合同名称</span><Input size="small" placeholder="输入合同名称..." v-model="name" style="width: 300px" /><span class="text-red">（*必填）</span>
      </row>
      <row class="p-b-10">
        <span class="title">发布类型</span>
        <i-select  size="small" v-model="contractCategory"  style="width: 300px" >
              <i-option
                v-for="type in contractTypes"
                :key="type.id"
                :value="type.id"
                >{{ type.name }}</i-option
              >
            </i-select>
        <!-- <Input size="small" placeholder="输入合同名称..." v-model="name" style="width: 300px" /> -->

        <span class="text-red">（*必填）</span>
      </row>
      <row class="p-b-10">
        <span class="title">合同甲方</span><a  @click="showFisrtModal=true">配置甲方</a>
      </row>
      <div class="workplatform-title" v-if="firstParty.id">甲方信息</div>
      <row class="p-b-10"  v-if="firstParty.id">
          <i-col span="8"><span  class="title">甲方名称 </span> {{firstParty.name}}</i-col>
          <i-col span="8"><span  class="title">联系人 </span> {{firstParty.contact}}</i-col>
          <i-col span="8"><span  class="title">联系电话 </span> {{firstParty.tel}}</i-col>
          <i-col span="8"><span  class="title">发票类型 </span><Tag :color="firstParty.invoiceType===1?'#44bd32':'#a59426'" type="border"> {{firstParty.invoiceTypeName}}</Tag></i-col>

          <i-col span="8"><span  class="title">开户银行 </span> {{firstParty.bank}}</i-col>
          <i-col span="8"><span  class="title">银行账号 </span> {{firstParty.bankAccount}}</i-col>
          <i-col span="8"><span  class="title">税号 </span> {{firstParty.code}}</i-col>
      </row>
      <row class="p-b-10">
        <span  class="title">备注 </span><Input type="textarea" v-model="model.mark" :rows="4" placeholder="合同备注信息..." />
      </row>
      <row class="p-b-10">
        <i-col span="24" class="text-right">
          <Button  :disabled="nextStepDisabled" type="info" :loading="loading" @click="handleNextStep">下一步</Button>
        </i-col>
      </row>
      <Modal v-model="showFisrtModal" width="800" :footer-hide="true">
         <div slot="header" >
            <p>选择合同甲方</p>
        </div>
        <first-party-picker :advertiserId="params.advertiserResultBean.id" :userId="params.userId" :current="firstParty" @on-chosen="handleFirstPartyChosen" v-if="showFisrtModal"></first-party-picker>
      </Modal>
    </div>
</template>

<script>
import { getCustomer } from '@/api/scp/myfirstparty'
import firstPartyPicker from '../steps/FirstPartyPicker'

import { getcategorylist } from '@/api/scp/scpbusiness'

export default {
  props: {
    params: {
      type: Object,
      required: true
    },
    stepType: {
      type: Number,
      default: 1
    }
  },
  components: {
    firstPartyPicker
  },
  data () {
    return {
      model: {},
      name: '',
      contractTypes: [],
      contractCategory: 0,
      firstParty: {},
      showFisrtModal: false,
      loading: false
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      this.model = this.params
      this.name = this.params.contractName === '默认名称' ? '' : this.params.contractName
      this.contractCategory = this.params.contractCategory
      getcategorylist({}).then(res => {
        this.contractTypes = res
      })
      getCustomer({ id: this.params.customerInfoBean.id }).then(res => {
        this.firstParty = res
      })
    },

    handleNextStep () {
      this.loading = true
      let data = null
      if (this.stepType === 1) { // 创建合同
        data = {
          contractId: this.params.contractId,
          mark: this.model.mark,
          contractCategory: this.contractCategory,
          name: this.name,
          customerId: this.firstParty.id,
          firstParty: this.firstParty
        }
      } else { // 合同变更
        data = {
          basic: this.model,
          contractName: this.name,
          firstParty: this.firstParty
        }
      }

      this.$emit('next-step', data)
    },
    handleFirstPartyChosen (firstParty) {
      this.firstParty = firstParty
      this.showFisrtModal = false
    },
    cannelLoading () {
      this.loadingNext = false
    }
  },
  computed: {
    nextStepDisabled: function () {
      return !(this.name && this.firstParty.id)
    }
  },
  watch: {
    params () {
      this.initPageData()
    }
  }

}
</script>
